var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.error
        ? [_c("ci-errorpage", { attrs: { code: _vm.error.code } })]
        : _vm._e(),
      _vm.loading ? [_c("ci-loading")] : _vm._e(),
      _c("router-view", {
        class: { "router-error": _vm.error, "router-loading": _vm.loading },
        on: { "tracking-click": _vm.trackClick },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }