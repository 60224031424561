var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: `ci-portfolio-tile ci-card with-hover${
        _vm.withBorder ? " with-border" : ""
      }${_vm.variant ? " " + _vm.variant : ""}`,
    },
    [
      _vm.portfolio.model_portfolio
        ? _c("span", {
            staticClass:
              "ci-portfolio-tile__header_highlight ml-3 ml-md-4 px-3 py-1 font-weight-semi text-sm",
            domProps: {
              textContent: _vm._s(
                _vm.$t("ui.components.portfolios.tile.model_portfolio.header")
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-3 p-md-4" },
        [
          _c("div", { staticClass: "ci-portfolio-tile__header d-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "owner d-flex align-items-center h-100 flex-grow-1",
              },
              [
                _vm.isOwner
                  ? [
                      _c("span", {
                        staticClass: "text-muted d-flex align-items-center",
                        domProps: { textContent: _vm._s(_vm.ownerLabel) },
                      }),
                    ]
                  : [
                      _c("a", {
                        staticClass:
                          "font-weight-bold text-body d-flex align-items-center",
                        attrs: {
                          href: _vm.portfolio.owner.url,
                          target: "_blank",
                          rel: "noopener",
                        },
                        domProps: { textContent: _vm._s(_vm.ownerLabel) },
                      }),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "actions flex-shrink-0 mr-n2 mt-n2" }, [
              _vm.context !== "search"
                ? _c(
                    "div",
                    { staticClass: "d-flex text-muted" },
                    [
                      !_vm.followOnly
                        ? [
                            _vm.elementVisibility.history
                              ? _c("ci-button", {
                                  staticClass: "history",
                                  attrs: {
                                    icon: "mdi-history",
                                    tooltip: _vm.$t(
                                      "ui.shared.portfolios.actions.show_history"
                                    ),
                                    "notification-bubble":
                                      _vm.notificationBubbleVisibility,
                                  },
                                  on: { click: _vm.showHistory },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isOwner && _vm.elementVisibility.duplicate
                              ? _c("ci-button", {
                                  staticClass: "duplicate",
                                  attrs: {
                                    icon: "mdi-content-copy",
                                    tooltip: _vm.$t(
                                      "ui.shared.global.duplicate"
                                    ),
                                  },
                                  on: { click: _vm.duplicate },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "ci-dropdown-menu",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function ({ expanded }) {
                                        return [
                                          _vm.isOwner &&
                                          _vm.elementVisibility.visibility
                                            ? _c("ci-button", {
                                                staticClass: "visibility",
                                                attrs: {
                                                  icon: _vm.portfolio
                                                    .public_portfolio
                                                    ? "mdi-earth"
                                                    : "mdi-lock",
                                                  tooltip: _vm.$t(
                                                    "ui.shared.portfolios.actions.set_visibility.title"
                                                  ),
                                                  active: expanded,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2945085228
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("ci-dropdown-item", {
                                  staticClass: "public",
                                  attrs: {
                                    name: _vm.$t(
                                      "ui.shared.global.visibility.public"
                                    ),
                                    description: _vm.$t(
                                      "ui.shared.global.visibility.public_description"
                                    ),
                                    "icon-classes": "mdi mdi-18px mdi-earth",
                                    "toggle-active":
                                      _vm.portfolio.public_portfolio,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setVisibility(true)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("ci-dropdown-item", {
                                  staticClass: "private",
                                  attrs: {
                                    name: _vm.$t(
                                      "ui.shared.global.visibility.private"
                                    ),
                                    description: _vm.$t(
                                      "ui.shared.global.visibility.private_description"
                                    ),
                                    "icon-classes": "mdi mdi-18px mdi-lock",
                                    "toggle-active":
                                      !_vm.portfolio.public_portfolio,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setVisibility(false)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.elementVisibility.share
                              ? _c("ci-share", {
                                  attrs: {
                                    tooltip: _vm.$t(
                                      !_vm.isOwner &&
                                        !_vm.portfolio.public_portfolio
                                        ? "ui.shared.portfolios.actions.share_private"
                                        : "ui.shared.global.share"
                                    ),
                                    "tracking-target":
                                      _vm.sharingOptions.trackingTarget,
                                    url: _vm.sharingOptions.sharingUrl,
                                    title: _vm.portfolio.name,
                                    type: "portfolio",
                                    disabled:
                                      !_vm.isOwner &&
                                      !_vm.portfolio.public_portfolio,
                                    networks: _vm.sharingNetworks,
                                    "network-tooltips": {
                                      custom_email: _vm.portfolio
                                        .public_portfolio
                                        ? null
                                        : _vm.$t(
                                            "ui.shared.portfolios.actions.share_email_private"
                                          ),
                                    },
                                    "disabled-networks": _vm.disabledNetworks,
                                    "dropdown-position": "bottom",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "ci-dropdown-menu",
                              {
                                attrs: { placement: "bottom-end" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function ({ expanded }) {
                                        return [
                                          _vm.isOwner
                                            ? _c("ci-button", {
                                                staticClass: "more",
                                                attrs: {
                                                  icon: "mdi-dots-vertical",
                                                  tooltip: _vm.$t(
                                                    "ui.shared.global.more"
                                                  ),
                                                  active: expanded,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4145923620
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("ci-dropdown-item", {
                                  staticClass: "rename",
                                  attrs: {
                                    name: _vm.$t("ui.shared.global.rename"),
                                    "icon-classes": "mdi mdi-18px mdi-pencil",
                                  },
                                  on: { click: _vm.rename },
                                }),
                                _vm._v(" "),
                                _vm.elementVisibility.duplicate
                                  ? _c("ci-dropdown-item", {
                                      staticClass: "duplicate",
                                      attrs: {
                                        name: _vm.$t(
                                          "ui.shared.global.duplicate"
                                        ),
                                        "icon-classes":
                                          "mdi mdi-18px mdi-content-copy",
                                      },
                                      on: { click: _vm.duplicate },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("ci-dropdown-item", {
                                  staticClass: "remove",
                                  attrs: {
                                    name: _vm.$t("ui.shared.global.delete"),
                                    "icon-classes": "mdi mdi-18px mdi-delete",
                                  },
                                  on: { click: _vm.confirmRemove },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm.followOnly && _vm.isOwner
                        ? [
                            _c("ci-button", {
                              staticClass: "visibility",
                              attrs: {
                                icon: _vm.portfolio.public_portfolio
                                  ? "mdi-earth"
                                  : "mdi-lock",
                                tooltip: _vm.$t(
                                  "ui.shared.portfolios.actions.set_visibility.title"
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setVisibility(
                                    !_vm.portfolio.public_portfolio
                                  )
                                },
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isOwner && _vm.$user.signedIn
                        ? [
                            _c("ci-bookmark", {
                              attrs: {
                                item: _vm.portfolio,
                                type: _vm.portfolio.model_portfolio
                                  ? "model_portfolios"
                                  : "portfolios",
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "ci-router-link",
            {
              staticClass: "ci-portfolio-tile__content text-decoration-none",
              attrs: {
                to: {
                  name: _vm.$routes.PORTFOLIOS_SHOW,
                  params: { id: _vm.portfolio.slug },
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "ci-portfolio-tile__click-container",
                  on: {
                    click: function ($event) {
                      _vm.$emit("tracking-click", {
                        targetName: "Portfolio",
                        targetId: _vm.portfolio.id.toString(),
                      })
                    },
                  },
                },
                [
                  _c("h5", {
                    staticClass: "mt-2 mb-3 text-dark",
                    domProps: { textContent: _vm._s(_vm.portfolio.name) },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex text-muted text-xs" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip",
                            value: _vm.$t("ui.shared.global.creation_date"),
                            expression: "$t('ui.shared.global.creation_date')",
                          },
                        ],
                        staticClass: "d-flex align-items-center mr-4",
                      },
                      [
                        _c("div", {
                          staticClass: "mdi mdi-calendar-today mdi-18px mr-1",
                        }),
                        _vm._v(" "),
                        _c("span", {
                          directives: [
                            {
                              name: "ci-format-date",
                              rawName: "v-ci-format-date",
                              value: _vm.portfolio.created_at,
                              expression: "portfolio.created_at",
                            },
                          ],
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip",
                            value: _vm.$t("ui.shared.global.update_date"),
                            expression: "$t('ui.shared.global.update_date')",
                          },
                        ],
                        staticClass: "d-flex align-items-center",
                      },
                      [
                        _c("div", {
                          staticClass: "mdi mdi-history mdi-18px mr-1",
                        }),
                        _vm._v(" "),
                        _c("span", {
                          directives: [
                            {
                              name: "ci-format-date",
                              rawName: "v-ci-format-date",
                              value: _vm.portfolio.updated_at,
                              expression: "portfolio.updated_at",
                            },
                          ],
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex text-muted text-xs" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center mr-4" },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("ui.shared.portfolios.products_count", {
                                count: _vm.portfolio.portfolio_products.length,
                              })
                            ),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center mr-4" },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("ui.shared.portfolios.followers_count", {
                                count: _vm.portfolio.followers_count,
                              })
                            ),
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.elementVisibility.metrics
                    ? _c("div", { staticClass: "metrics pt-4" }, [
                        _c(
                          "div",
                          { staticClass: "d-none d-md-flex d-xxl-none" },
                          [
                            _c("div", { staticClass: "metric mr-4" }, [
                              _c("div", {
                                staticClass:
                                  "text-xs font-weight-semi text-dark text-uppercase",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "ui.shared.global.metrics.performance.abbr_with_year_period",
                                      { period: 1 }
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "ci-format-percent",
                                    rawName: "v-ci-format-percent.style",
                                    value:
                                      _vm.portfolio.performance
                                        .performance_1_year,
                                    expression:
                                      "portfolio.performance.performance_1_year",
                                    modifiers: { style: true },
                                  },
                                ],
                                staticClass: "font-weight-bold",
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "metric" }, [
                              _c("div", {
                                staticClass:
                                  "text-xs font-weight-semi text-dark text-uppercase",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "ui.shared.global.metrics.volatility.abbr_with_year_period",
                                      { period: 1 }
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "ci-format-percent",
                                    rawName: "v-ci-format-percent.style",
                                    value:
                                      _vm.portfolio.performance
                                        .volatility_1_year,
                                    expression:
                                      "portfolio.performance.volatility_1_year",
                                    modifiers: { style: true },
                                  },
                                ],
                                staticClass: "font-weight-bold",
                              }),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-md-none d-xxl-block" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "metric col-3" }, [
                              _c("div", {
                                staticClass:
                                  "text-xs font-weight-semi text-dark text-uppercase",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "ui.shared.global.metrics.performance.abbr_with_year_period",
                                      { period: 1 }
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "ci-format-percent",
                                    rawName: "v-ci-format-percent.style",
                                    value:
                                      _vm.portfolio.performance
                                        .performance_1_year,
                                    expression:
                                      "portfolio.performance.performance_1_year",
                                    modifiers: { style: true },
                                  },
                                ],
                                staticClass: "font-weight-bold",
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "metric col-3" }, [
                              _c("div", {
                                staticClass:
                                  "text-xs font-weight-semi text-dark text-uppercase",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "ui.shared.global.metrics.volatility.abbr_with_year_period",
                                      { period: 1 }
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "ci-format-percent",
                                    rawName: "v-ci-format-percent.style",
                                    value:
                                      _vm.portfolio.performance
                                        .volatility_1_year,
                                    expression:
                                      "portfolio.performance.volatility_1_year",
                                    modifiers: { style: true },
                                  },
                                ],
                                staticClass: "font-weight-bold",
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "metric col-3" }, [
                              _c("div", {
                                staticClass:
                                  "text-xs font-weight-semi text-dark text-uppercase",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "ui.shared.global.metrics.performance.abbr_with_year_period",
                                      { period: 3 }
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "ci-format-percent",
                                    rawName: "v-ci-format-percent.style",
                                    value:
                                      _vm.portfolio.performance
                                        .performance_3_years,
                                    expression:
                                      "portfolio.performance.performance_3_years",
                                    modifiers: { style: true },
                                  },
                                ],
                                staticClass: "font-weight-bold",
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "metric col-3" }, [
                              _c("div", {
                                staticClass:
                                  "text-xs font-weight-semi text-dark text-uppercase",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "ui.shared.global.metrics.volatility.abbr_with_year_period",
                                      { period: 3 }
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "ci-format-percent",
                                    rawName: "v-ci-format-percent.style",
                                    value:
                                      _vm.portfolio.performance
                                        .volatility_3_years,
                                    expression:
                                      "portfolio.performance.volatility_3_years",
                                    modifiers: { style: true },
                                  },
                                ],
                                staticClass: "font-weight-bold",
                              }),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _vm.chartData
                        ? _c("ci-highcharts", {
                            attrs: {
                              disabled: _vm.chartDisabled,
                              "disabled-text":
                                _vm.portfolio.portfolio_products.length > 0
                                  ? _vm.$t(
                                      "ui.shared.portfolios.insufficient_data"
                                    )
                                  : _vm.$t(
                                      "ui.shared.portfolios.no_products_available"
                                    ),
                              options: _vm.chartData,
                              "constructor-type": "stockChart",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "text-xs text-body",
                    domProps: { textContent: _vm._s(_vm.chartPeriodLabel) },
                  }),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }