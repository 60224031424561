var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "ci-footer" }, [
    _c("div", { staticClass: "ci-footer__logo-wrapper" }, [
      _c(
        "div",
        {
          staticClass:
            "container-mw flex-center py-4 flex-column text-white text-center",
        },
        [
          _c(
            "ci-router-link",
            {
              staticClass: "ci-footer__logo",
              attrs: { to: { name: _vm.$routes.HOME } },
              on: {
                click: function ($event) {
                  return _vm.trackClick($event, "logo")
                },
              },
            },
            [_c("img", { attrs: { src: _vm.logo, alt: "CAPinside Logo" } })]
          ),
          !_vm.$user.signedIn
            ? [
                _c("p", {
                  staticClass: "my-3 px-4",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("ui.components.footer.become_a_capinsider")
                    ),
                  },
                }),
                _c("ci-button", {
                  staticClass: "mx-1 flex-shrink-0 px-2",
                  attrs: {
                    id: "footer-register",
                    to: {
                      path: "/users/sign_up",
                      query: { onboarding_type: "footer" },
                    },
                    label: _vm.$t("ui.components.footer.register"),
                    variant: "filled-primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.trackClick($event, "register")
                    },
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "ci-footer__links-wrapper py-3" }, [
      _c("div", { staticClass: "container-mw" }, [
        _c(
          "div",
          {
            staticClass:
              "social d-flex justify-content-center flex-wrap p-sm-3 p-md-0",
          },
          [
            _c(
              "a",
              {
                staticClass:
                  "text-decoration-none m-3 flex-center flex-column flex-md-row text-white",
                attrs: {
                  href: "https://de.linkedin.com/company/capinsidecom",
                  target: "_blank",
                  rel: "nofollow",
                },
                on: {
                  click: function ($event) {
                    return _vm.trackClick($event, "linkedin")
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "mr-0 mr-md-2 mdi mdi-24px mdi-linkedin",
                }),
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("ui.components.footer.social.linkedin")
                    ),
                  },
                }),
              ]
            ),
            _c(
              "a",
              {
                staticClass:
                  "text-decoration-none m-3 flex-center flex-column flex-md-row text-white",
                attrs: {
                  href: "https://www.youtube.com/channel/UCFtWClZvKnR3XOlpSU8_vJw",
                  target: "_blank",
                  rel: "nofollow",
                },
                on: {
                  click: function ($event) {
                    return _vm.trackClick($event, "youtube")
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "mr-0 mr-md-2 mdi mdi-24px mdi-youtube",
                }),
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("ui.components.footer.social.youtube")
                    ),
                  },
                }),
              ]
            ),
            _c(
              "a",
              {
                staticClass:
                  "text-decoration-none m-3 flex-center flex-column flex-md-row text-white",
                attrs: {
                  href: "https://www.instagram.com/capinside",
                  target: "_blank",
                  rel: "nofollow",
                },
                on: {
                  click: function ($event) {
                    return _vm.trackClick($event, "instagram")
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "mr-0 mr-md-2 mdi mdi-24px mdi-instagram",
                }),
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("ui.components.footer.social.instagram")
                    ),
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "break" }),
            _c(
              "a",
              {
                staticClass:
                  "text-decoration-none m-3 flex-center flex-column flex-md-row text-white",
                attrs: {
                  href: "https://www.facebook.com/CAPinside",
                  target: "_blank",
                  rel: "nofollow",
                },
                on: {
                  click: function ($event) {
                    return _vm.trackClick($event, "facebook")
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "mr-0 mr-md-2 mdi mdi-24px mdi-facebook",
                }),
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("ui.components.footer.social.facebook")
                    ),
                  },
                }),
              ]
            ),
            _c(
              "a",
              {
                staticClass:
                  "text-decoration-none m-3 flex-center flex-column flex-md-row text-white",
                attrs: {
                  href: "https://www.tiktok.com/@capinside",
                  target: "_blank",
                  rel: "nofollow",
                },
                on: {
                  click: function ($event) {
                    return _vm.trackClick($event, "tiktok")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "mr-0 mr-md-2 tiktok-logo" },
                  [_c("inline-svg", { attrs: { src: _vm.tiktokLogo } })],
                  1
                ),
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("ui.components.footer.social.tiktok")
                    ),
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._m(0),
        _c(
          "div",
          { staticClass: "links d-flex justify-content-center flex-wrap" },
          [
            _c("ci-router-link", {
              staticClass: "text-decoration-none m-3 text-white",
              attrs: {
                to: {
                  name: _vm.$routes.COMPANIES_SERVICE_PROVIDERS_SHOW,
                  params: { slug: "capinside" },
                },
                label: _vm.$t("ui.components.footer.links.about_us"),
              },
              on: {
                click: function ($event) {
                  return _vm.trackClick($event, "about-us")
                },
              },
            }),
            _c("ci-router-link", {
              staticClass: "text-decoration-none m-3 text-white",
              attrs: {
                to: { name: _vm.$routes.IMPRINT },
                label: _vm.$t("ui.components.footer.links.imprint"),
              },
              on: {
                click: function ($event) {
                  return _vm.trackClick($event, "imprint")
                },
              },
            }),
            _c("ci-router-link", {
              staticClass: "text-decoration-none m-3 text-white",
              attrs: {
                to: { name: _vm.$routes.PRIVACY_POLICY },
                label: _vm.$t("ui.components.footer.links.privacy"),
              },
              on: {
                click: function ($event) {
                  return _vm.trackClick($event, "privacy")
                },
              },
            }),
            _c("ci-router-link", {
              staticClass: "text-decoration-none m-3 text-white",
              attrs: {
                to: { name: _vm.$routes.TERMS_AND_CONDITIONS },
                label: _vm.$t("ui.components.footer.links.tos"),
              },
              on: {
                click: function ($event) {
                  return _vm.trackClick($event, "tos")
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "ci-footer__to-top-wrapper py-4 flex-center" },
      [
        _c("ci-button", {
          directives: [
            { name: "ci-scroll-to-top", rawName: "v-ci-scroll-to-top" },
          ],
          attrs: {
            id: "scroll-up",
            variant: "outline-primary",
            size: "md",
            icon: "mdi-arrow-up",
            label: _vm.$t("ui.components.footer.to_top"),
          },
          on: {
            click: function ($event) {
              return _vm.trackClick($event, "tothetop")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "px-4 d-md-none" }, [
      _c("hr", { staticClass: "divider light" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }