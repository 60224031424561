
import { defineComponent, PropType, ref, computed, onMounted, nextTick } from "vue";
import { getModule } from "vuex-module-decorators";
import CiImgixLazy from "@components/base/imgix-lazy.vue";
import CiInterestTags from "@components/tags/interest-tags.vue";
import CiRouterLink from "@router/router-link.vue";
import { settingsService } from "@services/settings.service";
import { viewportService } from "@services/viewport.service";
import { ViewportStoreModule } from "@stores/viewport.store";
import { buildURL } from "@utils/build-url";
import { Emitter } from "@utils/events";

const ViewportStore = getModule(ViewportStoreModule);

export default defineComponent({
  name: "CiContentTile",
  components: {
    CiInterestTags,
    CiImgixLazy,
    CiRouterLink,
  },
  props: {
    tileData: {
      type: Object as PropType<Articles.TileData>,
      default: null,
      required: true,
    },
    variant: {
      type: String as PropType<Nullable<"featured" | "short">>,
      default: null,
    },
    withBorder: {
      type: Boolean,
      default: false,
    },
    withHover: {
      type: Boolean,
      default: true,
    },
    showCopyright: {
      type: Boolean,
      default: true,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    maxHeadlineLines: {
      type: Number,
      default: 0,
    },
    additionalTracking: {
      type: Object as PropType<Nullable<{ [id: string]: string }>>,
      default: () => ({}),
    },
    interestTagsTrackingIdentifierOverride: {
      type: String,
      default: "",
    },
    hasSmallTeaser: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["tracking-click"],
  setup(props) {
    const headlineBreaks = ref(0);
    const headlineRef = ref<HTMLElement | null>(null);
    const isFlipperTagsArticleTilesEnabled = computed(() => !!settingsService?.options.flippers.tags_article_tiles);

    const mobileMode = computed(() => ViewportStore.smartphone);

    const imageSizes = computed(() => {
      if (props.variant === "featured") return [400, 660, 620, 786, 725, 900];
      else return 660;
    });

    const imageParams = computed(() => {
      if (props.tileData.img.params) return { ...props.tileData.img.params, ar: "2:1" };
      return { ar: "2:1" };
    });

    const variantClass = computed(() => {
      const classes: Indexable = {
        "with-hover": props.withHover,
        "with-border": props.withBorder,
      };

      if (isFeatured.value || isShort.value) classes[props.variant!] = true;

      return classes;
    });

    const maxConversationHeadlineLines = computed(() =>
      isFlipperTagsArticleTilesEnabled.value && props.tileData.interest_tags?.length
        ? props.maxHeadlineLines - 1
        : props.maxHeadlineLines
    );

    const teaserClamp = computed(() => {
      if (isFeatured.value) {
        if (headlineBreaks.value <= 2) return 3;
        if (headlineBreaks.value === 3) return 2;
        if (headlineBreaks.value === 4) return 1;

        return 0;
      } else return 3;
    });

    const trackingTargetData = computed<CiTracking.TrackingTarget>(() => ({
      name: props.tileData.type,
      identifier: props.tileData.id.toString(),
    }));

    const typeIcon = computed(() => {
      switch (props.tileData.type) {
        case "Video":
          return "mdi-play-circle-outline";
        case "Webcast":
          return "mdi-television-play";
        case "Podcast":
          return "mdi-volume-high";
        default:
          return "";
      }
    });

    const isShort = computed(() => props.variant === "short");

    const isFeatured = computed(() => props.variant === "featured" && viewportService.check(["md", "lg", "xl", "xxl"]));

    const linkWithTracking = computed(() => {
      if (!props.additionalTracking) return props.tileData.url;

      return buildURL(props.tileData.url, { searchParams: props.additionalTracking });
    });

    const impressionObject = computed<CiTracking.Impression>(() => ({
      event: {
        action: "impression",
        value: null,
      },
      target: {
        name: props.tileData.type,
        identifier: `${props.tileData.id}`,
        url: props.tileData.url,
      },
      source: {
        name: null,
        identifier: window.location.href,
      },
    }));

    const fetchHeadlineBreaks = () => {
      if (headlineRef.value) {
        const headline = headlineRef.value as HTMLElement;
        const lineHeight = window.getComputedStyle(headline, null).getPropertyValue("line-height");
        const parsedLineHeight = parseInt(lineHeight.replace("px", "") || "24", 10);
        const elementHeight = headline.offsetHeight;
        headlineBreaks.value = elementHeight / parsedLineHeight;
      } else {
        headlineBreaks.value = 2;
      }
    };

    onMounted(async () => {
      if (isFeatured.value) {
        Emitter.on("ci::page::resized", () => {
          fetchHeadlineBreaks();
        });

        await nextTick();

        fetchHeadlineBreaks();
      }
    });

    return {
      mobileMode,
      headlineBreaks,
      isFlipperTagsArticleTilesEnabled,
      imageSizes,
      imageParams,
      variantClass,
      isShort,
      isFeatured,
      maxConversationHeadlineLines,
      typeIcon,
      teaserClamp,
      trackingTargetData,
      linkWithTracking,
      impressionObject,
    };
  },
});
