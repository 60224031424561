import axios from "axios";
import "array-find-last";
import "array-flat-polyfill";
import { settingsService } from "@services/settings.service";
import { utilityService } from "@services/utility.service";

class Service implements ChartApi.Service {
  readonly DEFAULT_GET_OPTIONS: ChartApi.GetOptions = {
    euro: false,
    range: "max",
    startingTimestamp: null,
    endingTimestamp: null,
  };

  private limit = 5;
  private axiosInstance = axios.create();

  public get: ChartApi.GetFn = async (ids, options) => {
    const normalizedOptions = { ...this.DEFAULT_GET_OPTIONS, ...options };

    if (normalizedOptions.startingTimestamp && normalizedOptions.range !== "max")
      throw new Error(`startingTimestamp only works for range max: ${JSON.stringify(options)}`);

    return await this.getNormalized(ids, normalizedOptions);
  };

  public getCollectionStartingTimestamp: ChartApi.GetCollectionStartingTimestampFn = (collection) =>
    collection[0] && collection[0][0] ? collection[0][0] / 1000 : 0;

  private async getNormalized(ids: string[], options: ChartApi.GetOptions): Promise<ChartApi.ResponseItem<number>[]> {
    const raw = await this.fetch(ids, options.euro, options.range);
    const parsed = this.convertTimestampsToMillis(raw);

    const startingTimestampToUse = options.startingTimestamp
      ? Math.max(options.startingTimestamp, this.youngestDataPointTimestamp(parsed))
      : this.youngestDataPointTimestamp(parsed);
    const sameStartingPoint = this.normalizeStartingPoint(parsed, startingTimestampToUse);
    const requestedEndingPoint = this.normalizeEndingPoint(sameStartingPoint, options.endingTimestamp);
    return this.normalizeTo100PercentStart(requestedEndingPoint);
  }

  private async fetch(ids: string[], euro: boolean, range: string): Promise<ChartApi.ResponseItem<number>[]> {
    if (utilityService.botOrSpider()) {
      console.warn("no data for Bots");
      return [];
    }

    const chunks = this.createChunks(ids);
    const chunkedResponse = await Promise.all(
      chunks.map(async (chunk) => {
        const url = this.createUrl(chunk, euro, range);
        const response: ChartApi.Response = await this.axiosInstance.get(url);

        if (response.errorMessage) {
          console.error(`error fetching performance data for: ${url} errorMessage: ${response.errorMessage}`);
          return [];
        }

        return response.data;
      })
    );

    return chunkedResponse.flat().filter((i) => i) as ChartApi.ResponseItem<number>[];
  }

  private normalizeStartingPoint(
    responses: ChartApi.ResponseItem<number>[],
    startingTimestamp: number
  ): ChartApi.ResponseItem<number>[] {
    return responses.map((response) => {
      const index = response.ts.findIndex((datapoint) => datapoint[0] >= startingTimestamp);

      response.ts = response.ts.slice(index);

      return response;
    });
  }

  private normalizeEndingPoint(
    responses: ChartApi.ResponseItem<number>[],
    endingTimestamp: Nullable<number>
  ): ChartApi.ResponseItem<number>[] {
    if (!endingTimestamp) return responses;

    return responses.map((response) => {
      const index = response.ts.findLastIndex((datapoint) => datapoint[0] <= endingTimestamp);

      response.ts = response.ts.slice(0, index + 1);

      return response;
    });
  }

  private normalizeTo100PercentStart(responses: ChartApi.ResponseItem<number>[]): ChartApi.ResponseItem<number>[] {
    return responses.map((response) => {
      if (response.ts.length <= 0) return response;

      const scaleFactor = 100 / response.ts[0][1];
      response.ts = response.ts.map((dataPoint) => [dataPoint[0], dataPoint[1] * scaleFactor]);
      return response;
    });
  }

  private youngestDataPointTimestamp(responses: ChartApi.ResponseItem<number>[]): number {
    return responses
      .map((r) => r.ts)
      .map((dataPoint) => dataPoint[0][0])
      .sort((a, b) => b - a)[0];
  }

  private convertTimestampsToMillis(items: ChartApi.ResponseItem<number>[]): ChartApi.ResponseItem<number>[] {
    return items.map((item) => ({
      meta: item.meta,
      ts: item.ts.map(([timestamp, value]) => [timestamp * 1000, value]),
    }));
  }

  private createChunks(ids: string[], limit?: number): string[][] {
    const cache: any[] = [];
    const tmp = [...ids];
    if (this.limit <= 0) return cache;
    while (tmp.length) cache.push(tmp.splice(0, limit || this.limit));
    return cache;
  }

  private createUrl(ids: string[], euro: boolean, range: string): string {
    const url = settingsService.options.chart_service_base_url;
    const joinedIds = ids.join(",");
    const rangeOption = range ? `&RANGE=${range}` : "";
    const euroOption = euro ? "&EUR=1" : "";
    return `${url}?IDS=${joinedIds}${rangeOption}${euroOption}`;
  }
}

export const chartApiService = new Service();
