
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import ResizeObserver from "resize-observer-polyfill";
import { throttle } from "throttle-debounce";
import { Constants } from "@app/constants";
import { GoogletagSupport } from "@components/ads/googletags-support";
import CiFooter from "@components/footer/footer.vue";
import CiHeader from "@components/header/header.vue";
import CiNoticeBar from "@components/notice_bar/notice-bar.vue";
import PortalTarget from "@components/plugins/portal-target.vue";
import CiProfessionalGate from "@components/professional_gate/professional-gate.vue";
import CiConfirmationReminderModal from "@components/users/onboarding/register/ConfirmationReminderModal.vue";
import { useConfirmationReminder } from "@composables/confirmationReminder";
import { settingsService } from "@services/settings.service";
import { viewportService } from "@services/viewport.service";
import { ViewportStoreModule } from "@stores/viewport.store";
import { Emitter } from "@utils/events";

const ViewportStore = getModule(ViewportStoreModule);

@Component({
  name: "ci-layout",
  components: {
    "ci-confirmation-reminder-modal": CiConfirmationReminderModal,
    "ci-footer": CiFooter,
    "ci-header": CiHeader,
    "ci-notice-bar": CiNoticeBar,
    "ci-professional-gate": CiProfessionalGate,
    "portal-target": PortalTarget,
  },
})
export default class extends Vue {
  public scrollPositionY = 0;
  public windowIsScrolled = false;
  public adsToBeLoaded: CIAdsMeta[] = [];
  private visibleConfirmationReminder = false;

  private updateElementSizes = throttle(100, (height: number, key: GlobalElementSizesKeys) => {
    const sizes = { ...ViewportStore.elementSizes };
    sizes[key] = height;

    const adsContainerTop = this.$refs["ads-container-top"] as Vue;
    const adsContainerRight = this.$refs["ads-container-right"] as Vue;
    if (adsContainerRight) {
      const top = sizes.header + (sizes.content_header_sticky || sizes.content_header);
      (adsContainerTop.$el as HTMLDivElement).style.top = `${top}px`;
      (adsContainerRight.$el as HTMLDivElement).style.top = `${top + 24}px`;
    }

    ViewportStore.setElementSizes(sizes);
  });

  get headerAdDisabled(): boolean {
    return !settingsService.hasFlipper("page_ads_header");
  }

  get showAdsContainerRight(): boolean {
    if (this.$user.signedIn && !this.$route.meta?.ads?.find((ad: CIAdsMeta) => ad.member)) {
      return false;
    }

    if (this.$route.meta?.ads?.find((ad: CIAdsMeta) => ad.sidebar) && viewportService.check(["xl", "xxl"])) {
      return settingsService.hasFlipper("page_ads");
    }

    return false;
  }

  get confirmationReminder(): boolean {
    const { showConfirmationReminder } = useConfirmationReminder();
    window.setTimeout(() => (this.visibleConfirmationReminder = showConfirmationReminder()), Constants.defaultDelay);

    return this.visibleConfirmationReminder;
  }

  public get wrapContent(): boolean {
    if (this.$route.meta && this.$route.meta.wrapContent !== undefined) return this.$route.meta?.wrapContent;

    return true;
  }

  public async mounted(): Promise<void> {
    this.setupScrollListener();
    const pageAdsEnabled = settingsService.hasFlipper("page_ads");
    if (pageAdsEnabled) {
      this.setupExternalContentListener();
    }

    if (this.$page.context === "spa") {
      Emitter.on("ci::routing::stop", async (routes) => {
        const routeChanged = !routes.from.name || routes.from.name !== routes.to.name; // is first page visit (no from.name) or to and from are different pages
        if (pageAdsEnabled) {
          if (routeChanged) {
            GoogletagSupport.destroySlots();
            this.adsToBeLoaded = routes.to?.meta?.ads || [];
          }
        }
        if (!routes.from.name) this.initializeElementSizeObservers();
      });
    } else {
      if (pageAdsEnabled) {
        GoogletagSupport.destroySlots();
        this.adsToBeLoaded = this.$route.meta?.ads || [];
      }
      this.initializeElementSizeObservers();
    }
  }

  protected destroyed(): void {
    window.removeEventListener("scroll", () => this.handleScrollDirection());
  }

  private initializeElementSizeObservers() {
    const content = this.$refs["page-content"] as Element;
    const contentHeader = this.$refs["content-header"] as Vue;

    this.initializeElementSizeObserver(contentHeader.$el, "content_header");
    this.initializeElementSizeObserver(content, "content");
  }

  private initializeElementSizeObserver(element: Element, key: GlobalElementSizesKeys) {
    this.updateElementSizes(element.clientHeight, key);
    new ResizeObserver((e) => this.updateElementSizes(e[0].contentRect.height, key)).observe(element);
  }

  private handleScrollDirection(): void {
    const lastScrollTop = this.scrollPositionY;

    this.scrollPositionY = window.scrollY;

    const startTimeoutOnScroll = setTimeout(() => {
      if (this.scrollPositionY > lastScrollTop) {
        this.windowIsScrolled = true;
      }
    }, 1750);

    if (this.scrollPositionY <= 0) {
      this.windowIsScrolled = false;

      clearTimeout(startTimeoutOnScroll);
    }
  }

  private setupScrollListener(): void {
    const handleThrottledScroll = throttle(100, this.handleScrollDirection);
    window.addEventListener("scroll", handleThrottledScroll);
  }

  private setupExternalContentListener(): void {
    Emitter.on("ci::ads::external::content::mounted", ({ itemId }) => {
      const adToBeLoaded = this.adsToBeLoaded.find((ad) => ad.dom_id === itemId);
      if (adToBeLoaded) {
        GoogletagSupport.initAds([adToBeLoaded], () => {
          Emitter.emit("ci::ads::display-ad", { itemId });
        });
      }
    });
  }
}
