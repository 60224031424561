import { ciTrackingService } from "@services/ci-tracking.service";

export const useInternalTracking = (emit?: InternalTracking.TrackClickEmit, sourceNamePrefix?: string) => {
  const propagateTrackClick: InternalTracking.HandleTrackClickFn = async (options) => {
    options.sourceName = `${sourceNamePrefix ?? ""}${options.sourceName ?? ""}`;
    if (emit) emit("tracking-click", options);
  };

  const trackClick: InternalTracking.HandleTrackClickFn = async (options) => {
    const { targetName, targetId, sourceName, targetUrl, eventValue } = options;
    if (targetName !== undefined && targetName !== "") {
      await ciTrackingService.trackClick(targetName, targetId, sourceName, targetUrl, eventValue);
    }
  };

  return { propagateTrackClick, trackClick };
};
