
import { Component, Vue } from "vue-property-decorator";
import CiLoading from "@components/base/loading.vue";
import CiErrorpage from "@router/errorpage.vue";
import { Loader } from "@composables/loader";
import { useInternalTracking } from "@composables/internalTracking";

@Component({
  name: "ci-router-view",
  components: { CiErrorpage, CiLoading },
  setup() {
    const { trackClick } = useInternalTracking();

    return { trackClick };
  },
})
export default class extends Vue {
  public get error(): Nullable<RouterError> {
    return Loader.error.state;
  }

  public get loading(): boolean {
    return Loader.loading.state;
  }

  protected created(): void {
    this.$page.context = "spa";
  }
}
