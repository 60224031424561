
import { Component, Vue } from "vue-property-decorator";
import Logo from "@images/logos/logo-ci-white.svg";
import { getModule } from "vuex-module-decorators";
import { ViewportStoreModule } from "@stores/viewport.store";
import { throttle } from "throttle-debounce";
import ResizeObserver from "resize-observer-polyfill";
import { TikTok } from "@pictograms";
import InlineSvg from "vue-inline-svg";
import { ciTrackingService } from "@services/ci-tracking.service";

const ViewportStore = getModule(ViewportStoreModule);

@Component({
  name: "ci-footer",
  components: {
    "inline-svg": InlineSvg,
  },
})
export default class extends Vue {
  public get logo(): string {
    return Logo as any;
  }

  public get tiktokLogo(): string {
    return TikTok as any;
  }

  public trackClick(event: Event, targetIdentifier: string): void {
    ciTrackingService.trackClick("ButtonOnFooter", targetIdentifier);
  }

  protected mounted(): void {
    new ResizeObserver(
      throttle(100, (entries: ResizeObserverEntry[]) => {
        const sizes = { ...ViewportStore.elementSizes, footer: entries[0].contentRect.height };

        ViewportStore.setElementSizes(sizes);
      })
    ).observe(this.$el);
  }
}
