var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "header d-flex align-items-center",
      on: { click: _vm.sort },
    },
    [
      _vm.hasDefaultSlot
        ? [_c("span", { staticClass: "name mr-2" }, [_vm._t("default")], 2)]
        : _c("span", {
            staticClass: "name mr-2",
            domProps: { textContent: _vm._s(_vm.name) },
          }),
      _c(
        "div",
        { class: { "button-header": true, invisible: _vm.hideSortButton } },
        [
          _c("ci-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.order === "desc",
                expression: "order === 'desc'",
              },
            ],
            staticClass: "desc-button sort-arrow invisible",
            class: { "text-primary active": _vm.active && !_vm.hideSortButton },
            attrs: {
              iconClass: "mdi-16px",
              icon: "mdi-arrow-down",
              size: "xs",
            },
            on: { click: _vm.clickHandlerDesc },
          }),
          _c("ci-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.order === "asc",
                expression: "order === 'asc'",
              },
            ],
            staticClass: "asc-button sort-arrow invisible",
            class: { "text-primary active": _vm.active && !_vm.hideSortButton },
            attrs: { icon: "mdi-arrow-up", iconClass: "mdi-16px", size: "xs" },
            on: { click: _vm.clickHandlerAsc },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }