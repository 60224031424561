var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _vm.useSmallTile
      ? _c(
          "div",
          {
            key: "small",
            staticClass: "ci-card ci-product-tile d-flex ci-teaser-tile",
            class: _vm.classes,
          },
          [
            _c(
              "ci-router-link",
              _vm._b(
                {
                  directives: [
                    {
                      name: "ga-track-click",
                      rawName: "v-ga-track-click",
                      value: _vm.trackingData,
                      expression: "trackingData",
                    },
                  ],
                  staticClass:
                    "ci-product-tile__content text-decoration-none w-100 d-flex flex-column py-2 py-lg-2 px-3 px-lg-3",
                },
                "ci-router-link",
                _vm.linkAttributes,
                false
              ),
              [
                _c(
                  "div",
                  {
                    staticClass: "ci-product-tile__click-container",
                    on: {
                      click: function ($event) {
                        _vm.$emit("tracking-click", {
                          targetName: "Product",
                          targetId: _vm.product.id.toString(),
                        })
                      },
                    },
                  },
                  [
                    _c("h3", {
                      staticClass: "headline text-dark",
                      class: _vm.isMobile ? "h6" : "h5",
                      domProps: { textContent: _vm._s(_vm.product.name) },
                    }),
                    _c(
                      "footer",
                      { staticClass: "row" },
                      [
                        _vm.context === "recommendation"
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 col-sm-3 col-lg-4 col-xxl-3",
                                },
                                [
                                  _c("span", {
                                    staticClass: "text-xs text-muted",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.information.performance_1y.label
                                      ),
                                    },
                                  }),
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "ci-format-percent",
                                        rawName: "v-ci-format-percent.style",
                                        value:
                                          _vm.information.performance_1y.value,
                                        expression:
                                          "information.performance_1y.value",
                                        modifiers: { style: true },
                                      },
                                    ],
                                    staticClass:
                                      "font-weight-bold line-height-md",
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-3 d-none d-sm-block d-lg-none d-xxl-block",
                                },
                                [
                                  _c("span", {
                                    staticClass: "text-xs text-muted",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.information.performance_3y.label
                                      ),
                                    },
                                  }),
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "ci-format-percent",
                                        rawName: "v-ci-format-percent.style",
                                        value:
                                          _vm.information.performance_3y.value,
                                        expression:
                                          "information.performance_3y.value",
                                        modifiers: { style: true },
                                      },
                                    ],
                                    staticClass:
                                      "font-weight-bold line-height-md",
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 col-sm-3 col-lg-4 col-xxl-3",
                                },
                                [
                                  _c("span", {
                                    staticClass: "text-xs text-muted",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.information.volatility_1y.label
                                      ),
                                    },
                                  }),
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "ci-format-percent",
                                        rawName: "v-ci-format-percent",
                                        value:
                                          _vm.information.volatility_1y.value,
                                        expression:
                                          "information.volatility_1y.value",
                                      },
                                    ],
                                    staticClass:
                                      "font-weight-bold text-dark line-height-md",
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 col-sm-3 col-lg-4 col-xxl-3",
                                },
                                [
                                  _c("span", {
                                    staticClass: "text-xs text-muted",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.information.sum_ongoing_charges
                                          .label
                                      ),
                                    },
                                  }),
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "ci-format-percent",
                                        rawName: "v-ci-format-percent",
                                        value:
                                          _vm.information.sum_ongoing_charges
                                            .value,
                                        expression:
                                          "information.sum_ongoing_charges.value",
                                      },
                                    ],
                                    staticClass:
                                      "font-weight-bold text-dark line-height-md",
                                  }),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            key: "full",
            staticClass: "ci-card ci-product-tile ci-content-margin",
            class: _vm.classes,
          },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-column h-100" },
              [
                _c(
                  "div",
                  {
                    staticClass: "ci-product-tile__actions d-flex",
                    class: _vm.product.has_product_attachments
                      ? "pt-1"
                      : "pt-0",
                  },
                  [
                    _vm.showCheckbox
                      ? _c("ci-checkbox", {
                          staticClass: "ci-product-tile__action--checkbox",
                          attrs: {
                            value: _vm.value,
                            disabled: _vm.isCheckboxDisabled,
                          },
                          on: { change: _vm.checkboxChangeHandler },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.bottom",
                            value: {
                              title: _vm.productAlarmTooltipContent,
                              html: true,
                            },
                            expression:
                              "{ title: productAlarmTooltipContent, html: true }",
                            modifiers: { hover: true, bottom: true },
                          },
                        ],
                      },
                      [
                        _vm.performanceAlarmVisible
                          ? _c("ci-button", {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value: "product-alarm-modal",
                                  expression: "'product-alarm-modal'",
                                },
                              ],
                              staticClass: "ci-product-tile__action--bookmark",
                              attrs: {
                                id: "set-alarm",
                                icon: _vm.product.performance_alarm
                                  ? "mdi-timer-edit"
                                  : "mdi-timer-plus-outline",
                                gated: true,
                                disabled: _vm.alarmButtonDisabled,
                                variant: "ghost-tertiary",
                              },
                              on: { click: _vm.openProductAlarmModal },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.context !== "search"
                      ? _c("ci-bookmark", {
                          staticClass: "ci-product-tile__action--bookmark",
                          attrs: {
                            item: _vm.product,
                            type: "products",
                            "before-unfollow": _vm.confirmProductAlarmRemoval,
                            "button-size": "sm",
                          },
                          on: { unfollow: _vm.onUnfollow },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "ci-router-link",
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "ga-track-click",
                          rawName: "v-ga-track-click",
                          value: _vm.trackingData,
                          expression: "trackingData",
                        },
                      ],
                      staticClass: "ci-product-tile__chart-content",
                    },
                    "ci-router-link",
                    _vm.linkAttributes,
                    false
                  ),
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ci-product-tile__click-container",
                        on: {
                          click: function ($event) {
                            _vm.$emit("tracking-click", {
                              targetName: "Product",
                              targetId: _vm.product.id.toString(),
                            })
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ci-product-tile__chart",
                            class: {
                              "ci-product-tile__chart--is-empty":
                                !_vm.chartInitialized,
                              "ci-product-tile__chart--watchlist":
                                _vm.context === "watchlist",
                            },
                          },
                          [
                            _vm.chartInitialized
                              ? _c("ci-highcharts", {
                                  attrs: {
                                    options: _vm.chartData,
                                    "constructor-type": "stockChart",
                                  },
                                })
                              : _vm._e(),
                            _vm.showEmptyChartMessage && !_vm.hasChartData
                              ? _c("ci-button", {
                                  staticClass: "ci-product-tile__chart-empty",
                                  attrs: {
                                    variant: "filled-light",
                                    label: _vm.$t(
                                      "ui.shared.global.no_data_available"
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ci-product-tile__content text-decoration-none w-100 d-flex flex-column px-4",
                            class: _vm.product.has_product_attachments
                              ? "pt-5"
                              : "pt-4",
                          },
                          [
                            _c(
                              "header",
                              {
                                staticClass:
                                  "ci-product-tile__header d-flex align-items-center",
                                class:
                                  _vm.context === "recommendation"
                                    ? "mb-2"
                                    : "mb-3",
                              },
                              [
                                _vm.context === "recommendation"
                                  ? [
                                      _vm.volumeValue
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center mr-3 mt-n2",
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "text-xs text-muted",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "ui.shared.products.attributes.volume"
                                                    ) + ":"
                                                  ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                directives: [
                                                  {
                                                    name: "ci-format-currency",
                                                    rawName:
                                                      "v-ci-format-currency",
                                                    value: {
                                                      value: _vm.volumeValue,
                                                      delimiter: " ",
                                                      symbol: "EUR",
                                                      decimals: 2,
                                                    },
                                                    expression:
                                                      "{ value: volumeValue, delimiter: ' ', symbol: 'EUR', decimals: 2 }",
                                                  },
                                                ],
                                                staticClass:
                                                  "text-xs text-muted font-weight-bold",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.product.sustainable_preference
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-none d-sm-flex d-lg-none d-xl-flex mr-3 mt-n2 align-items-center",
                                            },
                                            [
                                              _c("ci-sfdr", {
                                                attrs: {
                                                  value:
                                                    _vm.product
                                                      .sustainable_preference,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  : [
                                      _vm.product.isin
                                        ? _c("span", {
                                            staticClass: "text-xs text-muted",
                                            domProps: {
                                              textContent: _vm._s(
                                                `${_vm.$t(
                                                  "activerecord.attributes.product.isin"
                                                )}: ${_vm.product.isin}`
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            ),
                            _c("h4", {
                              staticClass: "h6 text-dark flex-grow-1",
                              domProps: {
                                textContent: _vm._s(_vm.product.name),
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ci-product-tile__content ci-product-tile__content--is-footer text-decoration-none w-100 d-flex flex-column px-4 pb-4",
                          },
                          [
                            _c(
                              "footer",
                              {
                                staticClass: "row",
                                class: {
                                  "align-items-end":
                                    _vm.context !== "recommendation",
                                },
                              },
                              [
                                _vm.context === "recommendation"
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-4 col-sm-3 col-lg-4 col-xxl-3 mb-3",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "text-xs text-muted",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.information.performance_1y
                                                  .label
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "ci-format-percent",
                                                rawName:
                                                  "v-ci-format-percent.style",
                                                value:
                                                  _vm.information.performance_1y
                                                    .value,
                                                expression:
                                                  "information.performance_1y.value",
                                                modifiers: { style: true },
                                              },
                                            ],
                                            staticClass:
                                              "font-weight-bold line-height-md",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-3 d-none d-sm-block d-lg-none d-xxl-block mb-3",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "text-xs text-muted",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.information.performance_3y
                                                  .label
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "ci-format-percent",
                                                rawName:
                                                  "v-ci-format-percent.style",
                                                value:
                                                  _vm.information.performance_3y
                                                    .value,
                                                expression:
                                                  "information.performance_3y.value",
                                                modifiers: { style: true },
                                              },
                                            ],
                                            staticClass:
                                              "font-weight-bold line-height-md",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-4 col-sm-3 col-lg-4 col-xxl-3 mb-3",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "text-xs text-muted",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.information.volatility_1y
                                                  .label
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "ci-format-percent",
                                                rawName: "v-ci-format-percent",
                                                value:
                                                  _vm.information.volatility_1y
                                                    .value,
                                                expression:
                                                  "information.volatility_1y.value",
                                              },
                                            ],
                                            staticClass:
                                              "font-weight-bold text-dark line-height-md",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-4 col-sm-3 col-lg-4 col-xxl-3 mb-3",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "text-xs text-muted",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.information
                                                  .sum_ongoing_charges.label
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "ci-format-percent",
                                                rawName: "v-ci-format-percent",
                                                value:
                                                  _vm.information
                                                    .sum_ongoing_charges.value,
                                                expression:
                                                  "information.sum_ongoing_charges.value",
                                              },
                                            ],
                                            staticClass:
                                              "font-weight-bold text-dark line-height-md",
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "col-6 mb-2" }, [
                                        _c("span", {
                                          staticClass: "text-xs text-muted",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.information.distribution_type
                                                .label
                                            ),
                                          },
                                        }),
                                        _c("div", {
                                          staticClass:
                                            "text-body line-height-md",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.information.distribution_type
                                                .value
                                            ),
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "col-6 mb-2" }, [
                                        _c("span", {
                                          staticClass: "text-xs text-muted",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.information.focus_region.label
                                            ),
                                          },
                                        }),
                                        _c("div", {
                                          staticClass:
                                            "text-body line-height-md",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.information.focus_region.value
                                            ),
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "col-12" }, [
                                        _c("span", {
                                          staticClass: "text-xs text-muted",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.information.focus_asset.label
                                            ),
                                          },
                                        }),
                                        _c("div", {
                                          staticClass:
                                            "text-body line-height-md",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.information.focus_asset.value
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]
                                  : _vm.context === "watchlist"
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "col-6 text-left" },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "text-xs ci-product-tile__perf-inception-label",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.information
                                                  .performanceSinceInception
                                                  .label
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "ci-format-percent",
                                                rawName:
                                                  "v-ci-format-percent.style",
                                                value:
                                                  _vm.information
                                                    .performanceSinceInception
                                                    .value,
                                                expression:
                                                  "information.performanceSinceInception.value",
                                                modifiers: { style: true },
                                              },
                                            ],
                                            staticClass:
                                              "font-weight-bold ci-product-tile__perf-inception-value",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-6 text-left" },
                                        [
                                          _c("span", {
                                            staticClass: "text-xs text-muted",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.information
                                                  .performanceForOneYear.label
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "ci-format-percent",
                                                rawName:
                                                  "v-ci-format-percent.style",
                                                value:
                                                  _vm.information
                                                    .performanceForOneYear
                                                    .value,
                                                expression:
                                                  "information.performanceForOneYear.value",
                                                modifiers: { style: true },
                                              },
                                            ],
                                            staticClass: "font-weight-bold",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-12 text-left mt-2",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "text-xs text-muted",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.information.focus_asset
                                                  .label
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            staticClass:
                                              "text-body line-height-md",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.information.focus_asset
                                                  .value
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "div",
                                        { staticClass: "col-4 text-left" },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "font-weight-semi text-xs text-uppercase text-body",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.information.performance
                                                  .label
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "ci-format-percent",
                                                rawName:
                                                  "v-ci-format-percent.style",
                                                value:
                                                  _vm.information.performance
                                                    .value,
                                                expression:
                                                  "information.performance.value",
                                                modifiers: { style: true },
                                              },
                                            ],
                                            staticClass: "font-weight-bold",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-8 text-right" },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "font-weight-semi text-xs text-uppercase text-body",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.information.volatility.label
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "ci-format-percent",
                                                rawName:
                                                  "v-ci-format-percent.style",
                                                value:
                                                  _vm.information.volatility
                                                    .value,
                                                expression:
                                                  "information.volatility.value",
                                                modifiers: { style: true },
                                              },
                                            ],
                                            staticClass: "font-weight-bold",
                                          }),
                                        ]
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm.product.has_product_attachments
                  ? _c("ci-product-mediathek-label", {
                      attrs: { "data-testid": "fonds-mediathek-label" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }