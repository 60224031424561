var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "ci-card flex-center ci-section ci-content-padding",
      attrs: { id: "errorpage" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "errorpage__container flex-center flex-column text-center",
        },
        [
          _vm.image
            ? _c("inline-svg", {
                staticClass: "mb-4 mb-md-6",
                attrs: { src: _vm.image, height: "100%" },
              })
            : _vm._e(),
          _c("h1", {
            staticClass: "errorpage__headline mb-4",
            domProps: { textContent: _vm._s(_vm.headline) },
          }),
          _c("p", {
            staticClass: "errorpage__description",
            domProps: { innerHTML: _vm._s(_vm.description) },
          }),
          _c(
            "form",
            {
              staticClass: "w-100 pt-4 pt-md-5 pb-4",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.search.apply(null, arguments)
                },
              },
            },
            [
              _c("ci-input", {
                attrs: {
                  label: _vm.$t(
                    "ui.components.router.errorpages.misc.search_label"
                  ),
                  "leading-icon": "mdi-magnify",
                },
                model: {
                  value: _vm.query,
                  callback: function ($$v) {
                    _vm.query = $$v
                  },
                  expression: "query",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-md-4" },
                  [
                    _c("ci-button", {
                      attrs: {
                        variant: "ghost-primary",
                        to: { name: _vm.$routes.HOME },
                        label: _vm.$t(
                          "ui.components.router.errorpages.misc.to_startpage"
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-4" },
                  [
                    _c("ci-button", {
                      attrs: {
                        variant: "ghost-primary",
                        to: { name: _vm.$routes.PRODUCTS_SEARCH_INDEX },
                        label: _vm.$t(
                          "ui.components.router.errorpages.misc.to_fundsearch"
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-4" },
                  [
                    _c("ci-button", {
                      attrs: {
                        variant: "ghost-primary",
                        to: { name: _vm.$routes.NEWSCENTER_INDEX },
                        label: _vm.$t(
                          "ui.components.router.errorpages.misc.to_newscenter"
                        ),
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }