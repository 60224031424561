
import { Component, Prop, Vue } from "vue-property-decorator";
import Error403 from "@images/error-403.svg";
import Error404 from "@images/error-404.svg";
import Error500 from "@images/error-500.svg";
import InlineSvg from "vue-inline-svg";

@Component({
  name: "ci-errorpage",
  components: {
    "inline-svg": InlineSvg,
  },
})
export default class extends Vue {
  @Prop({ required: true }) code!: number;

  public query: string = "";

  public get headline(): string {
    if ([403, 404].includes(this.code))
      return this.$t(`ui.components.router.errorpages.${this.code}.headline`) as string;
    return this.$t("ui.components.router.errorpages.500.headline") as string;
  }

  public get description(): string {
    if ([403, 404].includes(this.code))
      return this.$t(`ui.components.router.errorpages.${this.code}.description_html`) as string;
    return this.$t("ui.components.router.errorpages.500.description_html") as string;
  }

  public get image(): Nullable<ImportedSVG> {
    if (this.code === 403) return Error403;
    if (this.code === 404) return Error404;
    return Error500;
  }

  public search(): void {
    if (this.$page.context === "spa") this.$router.push({ name: this.$routes.SEARCH_INDEX, query: { q: this.query } });
    else window.location.href = `/search?q=${this.query}`;
  }
}
