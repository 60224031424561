import { render, staticRenderFns } from "./sortable-table-header.vue?vue&type=template&id=77d04424&scoped=true&lang=pug"
import script from "./sortable-table-header.vue?vue&type=script&lang=ts"
export * from "./sortable-table-header.vue?vue&type=script&lang=ts"
import style0 from "./sortable-table-header.vue?vue&type=style&index=0&id=77d04424&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77d04424",
  null
  
)

export default component.exports